import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  View,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Keyboard,
  TouchableOpacity,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  SpiritCard,
  WarningSignComponent,
} from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { removeEmptyKeys } from '@/Util/global'
import { RootState, getGlobal } from '@/Store'
import { navigate } from '@/Navigators/utils'
import { spiritActions } from '@/Store/Spirit'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

import {
  convertParams,
  convertPrice,
  convertRating,
  separateFlavors,
} from './filterModal/MainFilterModal'
import useGetFlavorData from './hooks/useGetFlavorData'
import { Portal, RadioButton } from 'react-native-paper'
import { SpiritSort } from '@/Services/modules/spirits/spirits.type'

const SORT_BTN_ARR = [
  {
    label: '綜合排名',
    value: SpiritSort.RANK_SCORE,
  },
  {
    label: '最高價格',
    value: SpiritSort.PRICE_HIGH_TO_LOW,
  },
  {
    label: '最低價格',
    value: SpiritSort.PRICE_LOW_TO_HIGH,
  },
]

interface SortModalProps {
  visible: boolean
  hideModal: () => void
  onSort: (value: string) => void
  sortValue: {
    label: string
    value: string
  }
  setSortValue: (value: { label: string; value: string }) => void
  handleSortSubmit: (value: string) => void
}
const SortModal = ({
  hideModal,
  onSort,
  sortValue,
  setSortValue,
  handleSortSubmit,
}: SortModalProps) => {
  const styles = getStyle()
  const { Layout, Fonts, Gutters } = useTheme()
  const [value, setValue] = useState<{
    label: string
    value: string
  }>(sortValue)

  const handleSubmit = () => {
    onSort(value.value)
    hideModal()
    handleSortSubmit(value.value)
  }

  return (
    <Portal>
      <View style={styles.filter}>
        <TouchableOpacity
          style={[Layout.fullHeight]}
          onPress={() => {
            hideModal()
          }}
        />
      </View>
      <View style={[Gutters.regularHPadding, styles.modalContent]}>
        <View style={[{ marginBottom: 100 }]}>
          {SORT_BTN_ARR.map((item, index) => (
            <TouchableOpacity
              key={index}
              style={styles.sortItem}
              onPress={() => {
                setValue(item)
                setSortValue(item)
              }}
            >
              <RadioButton
                value={item.value}
                status={value.value === item.value ? 'checked' : 'unchecked'}
                onPress={() => {
                  setValue(item)
                  setSortValue(item)
                }}
                color={Colors.primary}
              />
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {item.label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <TouchableOpacity
          style={styles.sortSubmitButton}
          onPress={handleSubmit}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            儲存
          </Text>
        </TouchableOpacity>
      </View>
    </Portal>
  )
}

const FilterSearchListContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.theme)
  const { searchList: searchListData } = useSelector(
    (state: RootState) => state.spirit,
  )

  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  const flatListRef = useRef<any>()

  const [
    getSearchSpiritsRequest,
    {
      isLoading: isGetSearchSpiritsLoading,
      isFetching: isGetSearchSpiritsFetching,
    },
  ] = spiritsApi.useLazyGetSearchSpiritsQuery()

  const { flavorsData } = useGetFlavorData()

  const [filters, setFilters] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [sortValue, setSortValue] = useState<{
    label: string
    value: string
  }>(SORT_BTN_ARR[0])
  const [visible, setVisible] = useState(false)

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const handleSort = () => {
    setSortValue(sortValue)
  }

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    dispatch(spiritActions.clearSearchList())
    setPage(1)

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.MainFilterModal, {
        ...route.params,
      })
    }
  }, [dispatch, navigation, route.params])

  const handleGoFilter = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
    })
  }, [navigation, route.params])

  // 將 metadata 放入 filters 中
  useLayoutEffect(() => {
    dispatch(spiritActions.clearSearchList())
    setPage(1)

    const { parents, children } = separateFlavors(
      route?.params?.flavor,
      flavorsData,
    )

    const paramsObj = {
      averageRating: convertRating(route?.params?.rating),
      marketPriceZh: convertPrice(
        route?.params?.minPrice,
        route?.params?.maxPrice,
      ),
      category: convertParams(route?.params?.category),
      categoryNot: convertParams(route?.params?.categoryNot),
      'flavors.slug': convertParams(parents),
      'flavors.children': convertParams(children),
      brand: convertParams(route?.params?.brand),
      country: convertParams(route?.params?.country),
      pairings: convertParams(route?.params?.pairings),
    }
    setSortValue(
      SORT_BTN_ARR.find(item => item.value === route?.params?.sorts) ||
        SORT_BTN_ARR[0],
    )
    setFilters(removeEmptyKeys(paramsObj))
  }, [route.params, flavorsData, dispatch])

  // 初次近來此頁面時發送 request
  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      dispatch(spiritActions.clearSearchList())
      const params = {
        filters: filters || '',
        sorts: sortValue.value,
        page,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dispatch])

  const handleFetchMoreData = useCallback(() => {
    const isNeedFetch =
      searchListData.meta.currentPage !== searchListData.meta.totalPages &&
      page !== searchListData.meta.totalPages

    if (isNeedFetch) {
      const nextPage = page + 1
      const params = {
        filters: filters || '',
        sorts: sortValue.value,
        page: nextPage,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
      setPage(nextPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getSearchSpiritsRequest, searchListData.meta, sortValue.value])

  // 計算篩選有幾個篩選條件
  const convertFilterItemNumber = () => {
    if (!route.params) {
      return 0
    }
    const category = convertParams(route.params?.category)
    const flavor = convertParams(route.params?.flavor)
    const brand = convertParams(route.params?.brand)
    const country = convertParams(route.params?.country)
    const region = convertParams(route.params?.region)
    const pairings = convertParams(route.params?.pairings)

    let number = 0

    if (category) {
      number += category.split(',').length
    }
    if (flavor) {
      number += flavor.split(',').length
    }
    if (brand) {
      number += brand.split(',').length
    }
    if (country) {
      number += country.split(',').length
    }
    if (region) {
      number += region.split(',').length
    }
    if (pairings) {
      number += pairings.split(',').length
    }
    return number
  }

  const handleClickCard = (id: string, titleUrlSlug: string) => {
    navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug,
      id,
    })
  }

  const handleSortSubmit = (value: string) => {
    hideModal()
    navigation.navigate(PagesEnum.FilterSearchListContainer, {
      ...route.params,
      sorts: value,
    })
    const params = {
      filters: filters || '',
      sorts: value,
      page: 1,
    }

    setPage(1)
    dispatch(spiritActions.clearSearchList())
    getSearchSpiritsRequest(removeEmptyKeys(params))
  }

  const renderCardList = ({ item }: any) => {
    const {
      title,
      id,
      imageUrl,
      ratings,
      titleUrlSlug,
      brand,
      marketPrice,
      flavors,
    } = item
    return (
      <SpiritCard
        key={id}
        id={id}
        onClick={() => handleClickCard(id, titleUrlSlug)}
        imageUrl={imageUrl}
        title={title}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
        marketPrice={marketPrice}
        flavors={flavors?.topN}
        showPrice={!!marketPrice}
        showFlavors={!!flavors?.topN?.length}
      />
    )
  }

  const renderEmptyView = () => {
    if (isGetSearchSpiritsFetching || isGetSearchSpiritsLoading) {
      return (
        <View style={[Layout.colCenter, Layout.fullHeight]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        {searchListData.data?.length === 0 && (
          <>
            <Image
              source={Images.search_list_empty}
              resizeMode="contain"
              style={[Gutters.regularBMargin, styles.emptyIcon]}
            />
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              抱歉，找不到相似的結果
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.regularBMargin,
                { color: Colors.secondaryText },
              ]}
            >
              請嘗試搜尋其他烈酒
            </Text>
          </>
        )}
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <FadeInView duration={500} style={Layout.fill}>
        <Header
          title={'探索'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Gutters.regularHPadding,
            Gutters.regularBPadding,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <TouchableOpacity style={[styles.sortButton]} onPress={showModal}>
            <Image
              source={Images.search_filter_sort_icon}
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {sortValue.label}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.sortButton]}
            onPress={handleGoFilter}
          >
            <Image
              source={Images.search_filter_filter_icon}
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              篩選
            </Text>
            <View style={[styles.sortItemNumber]}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                {convertFilterItemNumber()}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={[Layout.fill]}>
          {isGetSearchSpiritsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                ref={flatListRef}
                data={searchListData.data || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item.id.toString()}
                renderItem={renderCardList}
                onScroll={() => Keyboard?.dismiss()}
                refreshing
                scrollEnabled={!productImageLightBox?.visible}
                maxToRenderPerBatch={10}
                onEndReached={() => {
                  handleFetchMoreData()
                }}
                onEndReachedThreshold={0.1}
                getItemLayout={(data, index) => {
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListEmptyComponent={renderEmptyView}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
      {visible && (
        <SortModal
          visible={visible}
          hideModal={hideModal}
          onSort={handleSort}
          sortValue={sortValue}
          setSortValue={setSortValue}
          handleSortSubmit={handleSortSubmit}
        />
      )}
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    emptyIcon: {
      width: 150,
      height: 150,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    filter: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
    },
    modalContent: {
      width: '100%',
      maxWidth: 428,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#1E1E1E',
      opacity: 1,
      borderTopLeftRadius: 10,
      paddingBottom: 24,
    },
    sortButton: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.background.top,
      padding: 8,
      marginRight: 16,
    },
    sortItem: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: Colors.background.top,
      paddingRight: 16,
      paddingVertical: 16,
      alignItems: 'center',
    },
    sortItemNumber: {
      width: 18,
      height: 18,
      backgroundColor: Colors.primary,
      borderRadius: 18,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
    sortSubmitButton: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.primary,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 'auto',
    },
  })

export default FilterSearchListContainer
