// 如果中英文資料沒對起來，先隱藏且 log 提示
export const checkDataAndWaring = (
  zhName: string,
  enName: string,
  type: string,
) => {
  if (!zhName || !enName) {
    console.log(`${type} 的 ${zhName} 與 ${enName} 資料沒對起來`)
    return false
  }
  return true
}
