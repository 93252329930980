import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'
import { RootState } from '@/Store'

import { convertMetaData } from '../utils/convertMetaData'
import { checkDataAndWaring } from '../utils/checkDataAndWaring'

export interface BrandDataItem {
  zhName: string
  enName: string
  count: number
}

const useGetBrandsData = () => {
  const { language } = useSelector((state: RootState) => state.theme)
  const [initialData, setInitialData] = useState<BrandDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isSuccess: filterMetaDataIsSuccess,
    isLoading: filterMetaDataIsLoading,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得品牌資訊
  const {
    data: brandData,
    isSuccess: brandDataIsSuccess,
    isLoading: brandDataIsLoading,
  } = noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.BRAND,
    locale: language,
  })

  // 整理初始化資料
  useEffect(() => {
    if (brandDataIsSuccess && filterMetaDataIsSuccess) {
      const brandArr: BrandDataItem[] = []
      const brandMeta = convertMetaData(filterMetaData, 'brand')

      for (const brand of brandMeta) {
        const zhName = brandData?.data?.[brand.key] || ''
        const enName = brand.key

        const isDataValid = checkDataAndWaring(zhName, enName, 'brand')

        if (isDataValid) {
          brandArr.push({
            zhName,
            enName,
            count: brand.count,
          })
        }
      }
      setInitialData(brandArr)
    }
  }, [
    brandData?.data,
    brandDataIsSuccess,
    filterMetaData,
    filterMetaDataIsSuccess,
  ])

  return {
    brandsData: initialData,
    filterMetaDataIsLoading,
    brandDataIsLoading,
  }
}

export default useGetBrandsData
